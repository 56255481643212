import { storeToRefs } from 'pinia'
import { useBookingStore } from '@/store/booking'
import { useRegionStore } from '@/store/region'

export default defineNuxtRouteMiddleware(to => {
  // const localePath = useLocalePath()
  const { $localePath: localePath } = useNuxtApp() as any

  const productId = Number(to.params.pid)
  if (process.client) {
    const bookingStore = useBookingStore()
    const { product } = storeToRefs(bookingStore)
    if (product.value?.pid && product.value?.pid === productId) return
  }

  const regionStore = useRegionStore()
  const regionId = Number(to.params.rid) || regionStore.region?.region_id

  return navigateTo(localePath(`/regions/${regionId}/products/${productId}`), { replace: true })
})
